/* eslint-disable react/react-in-jsx-scope */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import noResultsImg from '@assets/img/no-results-logs.png';
import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { appContext, formatDateAndHour, getCurrentYear } from '@utils';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ALL_ADVISORS_QUERY, Client, ORGANIZATION_QUERY, OrganizationLog } from '@graphql';
import { useParams } from 'react-router-dom';
import StorageOutlined from '@mui/icons-material/StorageOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { AddClient } from '../AddClient';
import { DeleteClient } from '../DeleteClient';
import { EditClient } from '../EditClient';
import { ImportClient } from '../ImportClient';
import { InviteClient } from '../InviteClient';

function CustomToolbar({ isRowsSelected, handleInviteModal, handleDeleteModal }: any) {
  const [open, setOpen] = useState<boolean>(false);
  const [isImportClientOpen, setImportClientOpen] = useState<boolean>(false);
  const handleClose = () => {
    setOpen(!open);
  };
  const importClient = () => {
    setImportClientOpen(!isImportClientOpen);
  };
  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <div>
        <Button variant="contained" onClick={importClient}>
          Import Client
        </Button>
        <Button
          variant="contained"
          onClick={handleClose}
          sx={{
            ml: '1rem',
          }}
        >
          Add Client
        </Button>
        {isRowsSelected && (
          <>
            <Button
              variant="contained"
              onClick={() => handleInviteModal()}
              sx={{
                ml: '1rem',
              }}
            >
              Invite Clients
            </Button>
            <Button
              variant="contained"
              onClick={() => handleDeleteModal()}
              sx={{
                ml: '1rem',
              }}
            >
              Delete Clients
            </Button>
          </>
        )}
      </div>
      {open && <AddClient handleClose={handleClose} />}
      {isImportClientOpen && <ImportClient handleClose={importClient} />}
    </GridToolbarContainer>
  );
}

function LogItem({ log }: { log: OrganizationLog }) {
  // Define inline styles for the log item layout and design
  const styles = {
    main: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      paddingBlock: '1rem',
    } as React.CSSProperties,
    icon: {
      backgroundColor: '#333F66',
      color: '#EEF0F6',
      maxWidth: '32px',
      maxHeight: '32px',
      borderRadius: '50%',
      padding: '6px',
    } as React.CSSProperties,
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      justifyContent: 'flex-start',
    } as React.CSSProperties,
    adminNameSpan: {
      color: '#5FC6E3',
      fontSize: '12px',
      fontWeight: '600',
    } as React.CSSProperties,
    actionNameSpan: {
      color: '#DDE1EE',
      fontSize: '14px',
      fontWeight: '600',
    } as React.CSSProperties,
    timeSpan: {
      color: '#BCC3DC',
      fontSize: '12px',
    } as React.CSSProperties,
  };

  // JSX for rendering a single log item with an icon, admin name, action, and timestamp
  return (
    <div>
      <Divider />
      <div style={styles.main}>
        <SyncAltIcon style={styles.icon} />
        <div style={styles.infoContainer}>
          <span style={styles.adminNameSpan}>{log.superadmin.name}</span>
          <span style={styles.actionNameSpan}>{log.operationName}</span>
          <span style={styles.timeSpan}>{formatDateAndHour(log.createdAt)}</span>
        </div>
      </div>
    </div>
  );
}

interface LogsModalProps {
  open: boolean; // Indicates if the modal is open or closed
  onClose: () => void; // Callback function to close the modal
  logs: OrganizationLog[]; // List of logs to be displayed in the modal
}

function LogsModal({ open, onClose, logs }: LogsModalProps) {
  const [selected, setSelected] = useState<string[]>(['all']); // Stores the selected admins for filtering
  const [filteredLogs, setFilteredLogs] = useState<OrganizationLog[]>(logs); // Holds the filtered logs based on selection
  const [timePeriod, setTimePeriod] = useState<number>(-1); // Stores the selected time period for filtering

  // Function to filter logs based on the selected time period
  const getLogsBasedOnTime = (logsToFilter: OrganizationLog[], period?: number) => {
    const periodToUse = period ?? timePeriod; // Use the provided period or the current state value
    if (periodToUse === -1) return logsToFilter; // If period is -1, return all logs
    const now = new Date();
    const pastDate = new Date(now);
    pastDate.setDate(now.getDate() - periodToUse); // Calculate the start date for filtering

    // Filter logs within the specified time range
    return logsToFilter.filter((log) => {
      const date = new Date(log.createdAt);
      return date >= pastDate && date <= now;
    });
  };

  // Function to filter logs based on selected administrators
  const getLogsBasedOnAdmin = (logsToFilter: OrganizationLog[], admins?: string[]) => {
    const adminsToUse = admins ?? selected; // Use provided admins or the current selected ones
    if (adminsToUse.includes('all')) return logsToFilter; // If 'all' is selected, return all logs
    return logsToFilter.filter((log) => adminsToUse.includes(log.superadmin.id)); // Filter logs by admin IDs
  };

  // Handle change in selected administrators
  const handleChangeAdm = (event: any) => {
    const value = event?.target?.value;

    if (value.length === 0) return; // If no value is selected, do nothing

    const admins = typeof value === 'string' ? value.split(',') : value; // Split comma-separated string into array
    setSelected(admins);

    // Filter logs by both admin and time
    const filterByAdmins = getLogsBasedOnAdmin(logs, admins);
    const filterByTime = getLogsBasedOnTime(filterByAdmins);
    setFilteredLogs(filterByTime);
  };

  // Handle change in selected time period
  const handleChangeTime = (event: any) => {
    const period = event.target.value;
    setTimePeriod(period); // Update the selected time period
    setFilteredLogs(getLogsBasedOnTime(logs, period)); // Filter logs by the new time period
  };

  // Define styles for the drawer and its contents
  const drawerStyle = {
    main: {} as React.CSSProperties,
    paperProps: {
      style: {
        backgroundColor: '#000',
        backgroundImage: 'none',
        width: '384px',
        padding: '1rem',
      } as React.CSSProperties,
    },
    headerStyle: {
      container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '1.5rem',
        fontWeight: '700',
        marginBottom: '1.5rem',
        marginTop: '20px',
        paddingInline: '8px',
      } as React.CSSProperties,
      leftSide: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
      } as React.CSSProperties,
    },
    toolsSection: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      fontFamily: 'Inter',
      marginBottom: '1.5rem',
    } as React.CSSProperties,
    select: {
      borderRadius: '16px',
      color: '#BCC3DC',
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#232A43',
      },
    } as React.CSSProperties,
    selectLabel: {
      color: 'white',
      fontWeight: '600',
    } as React.CSSProperties,
    resultsName: {
      color: '#DDE1EE',
      fontSize: '16px',
      fontWeight: '600',
      marginBottom: '1rem',
    } as React.CSSProperties,
    logSection: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: '16px',
      overflow: 'hidden',
    } as React.CSSProperties,
    logItemsContainer: {
      flexGrow: 1,
      overflowY: 'auto',
    } as React.CSSProperties,
    emptySection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '60px',
    } as React.CSSProperties,
  };

  // Time period options for filtering
  const timeFilter = [
    { value: 7, label: 'Last 7 Days' },
    { value: 30, label: 'Last 30 Days' },
    { value: 90, label: 'Last 3 months' },
    { value: 180, label: 'Last 6 months' },
    { value: 365, label: `This year (${getCurrentYear()})` },
    { value: 730, label: `Last year (${getCurrentYear(1)})` },
    { value: -1, label: 'All Time' },
  ];

  // Generate a list of unique superadmins for the administrator filter
  const getSuperAdmins = () => {
    const saArray = logs.map((log) => ({
      value: log.superadmin.id,
      label: log.superadmin.name,
    }));
    return saArray.filter((item, idx) => saArray.findIndex((i) => i.value === item.value) === idx); // Filter out duplicate admins
  };
  const administratorFilter = [{ value: 'all', label: 'All Administrators' }].concat(
    getSuperAdmins(),
  );

  // Handle the rendering of the selected value in the administrator select
  const handleRenderValue = () => {
    if (selected.length === 1) {
      return administratorFilter.find((item) => item.value === selected[0])?.label;
    }
    return `${selected.length} Administrators`;
  };

  // Format the display of the number of results
  const formatResults = () => {
    if (filteredLogs.length === 0) return 'No results';
    if (filteredLogs.length === 1) return '1 Result';
    return `${filteredLogs.length} Results`;
  };

  return (
    <Drawer open={open} onClose={onClose} anchor="right" PaperProps={drawerStyle.paperProps}>
      <div style={drawerStyle.main}>
        <header style={drawerStyle.headerStyle.container}>
          <div style={drawerStyle.headerStyle.leftSide}>
            <StorageOutlined sx={{ color: '#5FC6E3' }} />
            <span>Activity Log</span>
          </div>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </header>

        <section style={drawerStyle.toolsSection}>
          <FormControl fullWidth>
            <InputLabel style={drawerStyle.selectLabel}>Administrator</InputLabel>
            <Select
              name="role"
              sx={drawerStyle.select}
              label="Administrator"
              multiple
              value={selected}
              onChange={handleChangeAdm}
              renderValue={handleRenderValue}
            >
              <MenuItem value="all">
                <Checkbox checked={selected.includes('all')} />
                <ListItemText primary="All Administrators" />
              </MenuItem>
              {getSuperAdmins().map((item, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={idx + item.label} value={item.value}>
                  <Checkbox checked={selected.includes(item.value)} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel style={drawerStyle.selectLabel}>Time Period</InputLabel>
            <Select value={timePeriod} onChange={handleChangeTime} sx={drawerStyle.select} label='Time Period'>
              {timeFilter.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </section>

        <section style={drawerStyle.logSection}>
          <span style={drawerStyle.resultsName}>{formatResults()}</span>
          {filteredLogs.length ? (
            <div style={drawerStyle.logItemsContainer}>
              {filteredLogs.map((log, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <LogItem key={idx} log={log} />
              ))}
            </div>
          ) : (
            <>
              <Divider orientation="horizontal" flexItem />
              <div style={drawerStyle.emptySection}>
                <img src={noResultsImg} alt="" />
              </div>
            </>
          )}
        </section>
      </div>
    </Drawer>
  );
}

export const OrganizationDetailTable = observer(() => {
  // const [openAdvisorModal, setOpenAdvisorModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [client, setClient] = useState<Client | GridRowId[] | null>(null);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);
  const [openLogsModal, setOpenLogsModal] = useState<boolean>(false);

  // const handleAdvisorModal = useCallback(() => {
  //   setOpenAdvisorModal(!openAdvisorModal);
  // }, [openAdvisorModal]);

  const handleDeleteModal = useCallback(() => {
    setOpenDeleteModal(!openDeleteModal);
  }, [openDeleteModal]);

  const handleInviteModal = useCallback(() => {
    setOpenInviteModal(!openInviteModal);
  }, [openInviteModal]);

  const handleEditModal = useCallback(() => {
    setOpenEditModal(!openEditModal);
  }, [openEditModal]);

  const handleLogsModal = useCallback(() => {
    setOpenLogsModal(!openLogsModal);
  }, [openLogsModal]);

  const {
    userStore: { allClients, getCurrentSession, sessionResponse, user },
  } = useContext(appContext);
  const { id } = useParams<any>();

  useEffect(() => {
    getCurrentSession({ superAdmin: [ORGANIZATION_QUERY(id), ALL_ADVISORS_QUERY] });
  }, [getCurrentSession, id]);

  const columns = useMemo<GridColumns>(
    () => [
      { field: 'id', headerName: 'ID', disableColumnMenu: true, flex: 1 },
      { field: 'email', headerName: 'Email', disableColumnMenu: true, flex: 1 },
      { field: 'workEmail', headerName: 'Work Email', disableColumnMenu: true, flex: 1 },
      { field: 'firstName', headerName: 'First Name', disableColumnMenu: true, flex: 1 },
      { field: 'lastName', headerName: 'Last Name', disableColumnMenu: true, flex: 1 },
      { field: 'phone', headerName: 'Phone', disableColumnMenu: true, flex: 1 },
      { field: 'role', headerName: 'Role', disableColumnMenu: true, flex: 1 },
      {
        field: 'invitationStatus',
        headerName: 'Invitation Status',
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: 'onboardingStatus',
        headerName: 'Onboarding Status',
        disableColumnMenu: true,
        flex: 1,
      },
      // {
      //   field: 'advisor',
      //   headerName: 'Advisor',
      //   disableColumnMenu: true,
      //   flex: 1,
      //   valueGetter: (params: any) => {
      //     if (params.row.advisor)
      //       return `${params.row.advisor.firstName} ${params.row.advisor.lastName}`;
      //     return null;
      //   },
      // },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) => [
          // <GridActionsCellItem
          //   icon={<SupervisorAccountIcon />}
          //   label={params.row.advisor ? 'Change Advisor' : 'Assign Advisor'}
          //   onClick={() => {
          //     setClient(params.row);
          //     handleAdvisorModal();
          //   }}
          //   showInMenu
          // />,
          <GridActionsCellItem
            icon={<SupervisorAccountIcon />}
            label={params.row.invited ? 'Invite Again' : 'Send Invite'}
            disabled={rowSelectionModel.length > 0}
            onClick={() => {
              setClient([params.row.id]);
              handleInviteModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete Client"
            onClick={() => {
              setClient([params.row.id]);
              handleDeleteModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit Client"
            onClick={() => {
              setClient(params.row);
              handleEditModal();
            }}
            showInMenu
          />,
        ],
      },
    ],
    [
      rowSelectionModel.length,
      // handleAdvisorModal,
      handleInviteModal,
      handleDeleteModal,
      handleEditModal,
    ],
  );

  const manageOrgUrl = `${process.env.REACT_APP_CLIENT_URL}/superadmin/organization?token=${user.organization?.manageOrgToken}`;
  const canManageOrg = !!user.organization?.manageOrgToken;

  return (
    <div style={{ height: 'inherit', width: '100%' }}>
      <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
        <h2 style={{ color: 'rgb(224, 224, 224)' }}>{user.organization?.name}</h2>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Button
            variant="outlined"
            sx={{ height: 'fit-content', color: 'white', borderColor: '#333F66' }}
            onClick={handleLogsModal}
          >
            <StorageOutlined sx={{ marginRight: '8px', color: '#5FC6E3' }} />
            ACTIVITY LOG
          </Button>
          {canManageOrg && (
            <Button
              variant="contained"
              sx={{ height: 'fit-content', backgroundColor: '#2F5DF5', color: 'white' }}
              href={manageOrgUrl}
              target="_blank"
            >
              <WorkOutlineIcon sx={{ marginRight: '8px' }} />
              Manage Organization
            </Button>
          )}
        </div>
      </div>
      <DataGrid
        rows={allClients as any}
        columns={columns}
        checkboxSelection
        onSelectionModelChange={(items) => setRowSelectionModel(items)}
        componentsProps={{
          toolbar: {
            isRowsSelected: rowSelectionModel.length > 0,
            handleInviteModal,
            handleDeleteModal,
          },
        }}
        loading={sessionResponse.status === 'pending'}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
      {/* {openAdvisorModal && (
        <AssignAdvisor handleClose={handleAdvisorModal} client={client as any} />
      )} */}
      {openDeleteModal && (
        <DeleteClient
          handleClose={handleDeleteModal}
          clients={rowSelectionModel.length ? rowSelectionModel : (client as GridRowId[])}
        />
      )}
      {openInviteModal && (
        <InviteClient
          handleClose={handleInviteModal}
          clients={rowSelectionModel.length ? rowSelectionModel : (client as GridRowId[])}
        />
      )}
      {openEditModal && <EditClient handleClose={handleEditModal} client={client as any} />}
      {user.organization?.orgLogs !== undefined && (
        <LogsModal
          open={openLogsModal}
          onClose={handleLogsModal}
          logs={user.organization?.orgLogs ?? []}
        />
      )}
    </div>
  );
});
