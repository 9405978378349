/**
 *
 * @param diff  If you want to get a year on the past or future, you can pass a number to
 *
 * @returns
 */
export const getCurrentYear = (diff = 0) => new Date().getFullYear() - diff;


/**
 *
 * @param dateString  A string representing a date
 *
 * @returns A string with the date formatted as "Month Day at HH:MM"
 */
export const formatDateAndHour = (dateString: string) => {
  const date = new Date(dateString);

  const options = { month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions;
  const formattedDate = date.toLocaleDateString('en-US', options);

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${formattedDate} at ${hours}:${minutes}`;
};
