/* eslint-disable consistent-return */
import {
  Advisor,
  AdvisorRequest,
  Client,
  ClientOrganization,
  LOGIN_MUTATION,
  RESET_PASSWORD_MUTATION,
  RESET_PASSWORD_REQUEST_MUTATION,
  SESSION_QUERY,
  Session,
  Settings,
  SuperAdmin,
  USER_FRAGMENT,
  UserType,
} from '@graphql';
import { store } from '@store';
import { graphqlApi, paths } from '@utils';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { flow, makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { DEFAULT_HTTP_RESPONSE } from './defaults';

const fConfig = {
  apiKey: process.env.REACT_APP_FB_K,
  authDomain: process.env.REACT_APP_FB_AD,
  projectId: process.env.REACT_APP_FB_PID,
  storageBucket: process.env.REACT_APP_FB_SB,
  messagingSenderId: process.env.REACT_APP_FB_MSI,
  appId: process.env.REACT_APP_FB_ID,
};

export class UserStore {
  userInfo: Session;

  isAuth = false;

  signInResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  sessionResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  resetPasswordResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  app: any;

  constructor() {
    makeAutoObservable(this);
    this.app = initializeApp(fConfig);
    // Subscribe to auth state changes
    //  const auth = getAuth();
    //  onAuthStateChanged(auth, (user) => {
    //    if (!user) {
    //     this.clearSession();
    //    }
    //  });
  }

  createSession = () => {
    sessionStorage.setItem('token', this.userInfo.auth.token);
    this.isAuth = true;
  };

  clearSession = () => {
    sessionStorage.clear();
    this.userInfo = {} as any;
    this.isAuth = false;
  };

  logIn = flow(function* logIn(
    this: UserStore,
    variables: { email: string; password: string; type: UserType },
  ): any {
    try {
      this.signInResponse.status = 'pending';
      const tokenResponse = yield this.signIn(variables.email, variables.password);
      if (tokenResponse) {
        const userResponse = yield graphqlApi(LOGIN_MUTATION, {
          email: variables.email,
          token: tokenResponse,
          type: variables.type,
        });
        this.signInResponse.status = 'ok';
        this.userInfo = userResponse?.data?.data?.login;

        if (this.userInfo) {
          this.createSession();
          toast('User logged in.', { type: 'success' });
        } else {
          toast('Invalid email or password.', { type: 'info' });
        }
        return userResponse;
      }
    } catch (err) {
      toast('Something went wrong!', { type: 'error' });
      this.signInResponse.status = 'badRequest';
      this.signInResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  signIn = flow(function* signIn(email, password): any {
    try {
      const auth = getAuth();
      const userCredential = yield signInWithEmailAndPassword(auth, email, password);
      const { user } = userCredential;
      return new Promise((resolve, reject) => {
        user
          .getIdToken()
          .then((token: any) => {
            // Additional logic after signing in the user
            resolve(token);
          })
          .catch((error: any) => {
            // get token error
            reject(error);
          });
      });
    } catch (error) {
      // Handle error
      throw error;
    }
  });

  getCurrentSession = flow(function* getCurrentSession(
    this: UserStore,
    query?: IResolvableFields,
    fragments?: Array<string>,
  ): any {
    try {
      this.sessionResponse.status = 'pending';
      const userResponse = yield graphqlApi(SESSION_QUERY(USER_FRAGMENT(query, fragments)));

      this.sessionResponse.status = 'ok';

      if (userResponse?.data?.errors) {
        return this.clearSession();
      }

      this.userInfo = {
        auth: {
          ...this.userInfo?.auth,
          ...userResponse?.data?.data?.currentSession?.auth,
        },
        user: {
          ...this.userInfo?.user,
          ...userResponse?.data?.data?.currentSession?.user,
        },
      };

      if (this.userInfo) this.createSession();
      else this.clearSession();
      return this.userInfo;
    } catch (err) {
      this.sessionResponse.status = 'badRequest';
      this.sessionResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  resetPasswordRequest = flow(function* resetPasswordRequest(
    this: UserStore,
    variables: { type: UserType; email: string },
  ): any {
    try {
      this.resetPasswordResponse.status = 'pending';
      const userResponse = yield graphqlApi(RESET_PASSWORD_REQUEST_MUTATION, variables);
      this.resetPasswordResponse.status = 'ok';
      const response = userResponse?.data?.data?.requestResetPassword;
      if (response) {
        toast('Password reset link sent to email.', { type: 'success' });
        store.historyStore.history.push(paths.login);
      } else {
        toast('No such account found.', { type: 'info' });
      }
      return userResponse;
    } catch (err) {
      toast('Something went wrong!', { type: 'error' });
      this.resetPasswordResponse.status = 'badRequest';
      this.resetPasswordResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  resetPassword = flow(function* resetPassword(
    this: UserStore,
    variables: { tokenId: string; userId: string; password: string; email?: string },
  ): any {
    try {
      this.resetPasswordResponse.status = 'pending';
      const userResponse = yield graphqlApi(RESET_PASSWORD_MUTATION, variables);
      this.resetPasswordResponse.status = 'ok';
      const response = userResponse?.data?.data?.resetPasswordAndEmail;
      if (response) {
        toast('Password reset successful.', { type: 'success' });
        store.historyStore.history.push(paths.login);
      } else {
        toast('Invalid credentials.', { type: 'info' });
      }
      return userResponse;
    } catch (err) {
      toast('Something went wrong!', { type: 'error' });
      this.resetPasswordResponse.status = 'badRequest';
      this.resetPasswordResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  get user(): SuperAdmin {
    const superAdmin = this.userInfo.user as SuperAdmin;

    return superAdmin || {};
  }

  get allClients(): Array<Client> {
    const superAdmin = this.userInfo.user as SuperAdmin;
    return superAdmin?.organization?.allClients || [];
  }

  get allAdvisors(): Array<Advisor> {
    const superAdmin = this.userInfo.user as SuperAdmin;
    return superAdmin?.allAdvisors || [];
  }

  get allAdvisorsRequests(): Array<AdvisorRequest> {
    const superAdmin = this.userInfo.user as SuperAdmin;
    return superAdmin?.allAdvisorRequests || [];
  }

  get allOrganizations(): Array<ClientOrganization> {
    const superAdmin = this.userInfo.user as SuperAdmin;
    return superAdmin?.allOrganizations || [];
  }

  get settings(): Settings {
    const superAdmin = this.userInfo.user as SuperAdmin;
    return superAdmin?.settings || ({} as Settings);
  }
}
