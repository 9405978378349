/* eslint-disable max-classes-per-file */

export * from './mutations';
export * from './queries';

export enum ClientRole {
  Admin = 'Admin',
  Manager = 'Manager',
  Basic = 'Basic',
  Spouse = 'Spouse',
}

export enum SuperAdminRole {
  Admin = 'Admin',
  Manager = 'Manager',
}

export enum UserType {
  SuperAdmin = 'SuperAdmin',
  Advisor = 'Advisor',
  Client = 'Client',
}

export class AdvisorInput {
  email: string;

  firstName: string;

  lastName: string;

  phone: string;

  title?: Nullable<string>;

  company: string;

  linkedinUrl?: Nullable<string>;

  bookingUrl?: Nullable<string>;

  bio?: Nullable<string>;

  password: string;
}

export class ManagerInput {
  email: string;

  name: string;
}

export class ClientInput {
  email: string;

  firstName: string;

  org: string;

  phone?: Nullable<string>;

  lastName: string;

  role?: Nullable<ClientRole>;
}

export class UpdateClientInput {
  id: string;

  firstName: string;

  lastName: string;

  phone?: Nullable<string>;

  role?: Nullable<ClientRole>;

  email: string;

  workEmail: string;
}

export class ClientOrganizationInput {
  name: string;

  email: string;

  firstName: string;

  lastName: string;

  status?: Nullable<OrganizationStatus>;

  message?: Nullable<string>;

  managerId?: Nullable<string>;

  advisorId?: Nullable<string>;
}

export class UpdateClientOrganizationInput {
  id: string;

  name: string;

  status: OrganizationStatus;

  advisorId?: Nullable<string>;
}

export enum OrganizationStatus {
  Acitve = 'Active',
  Inactive = 'Inactive',
}

export class Advisor {
  id: string;

  email: string;

  firstName: string;

  lastName: string;

  phone: string;

  title?: Nullable<string>;

  company: string;

  linkedinUrl?: Nullable<string>;

  bookingUrl?: Nullable<string>;

  bio?: Nullable<string>;
}

export class Client {
  id: string;

  email: string;

  workEmail: string;

  firstName: string;

  lastName: string;

  phone?: Nullable<string>;

  role: ClientRole;

  advisor?: Nullable<Advisor>;
}

export class ClientOrganization {
  id: string;

  name: string;

  status: OrganizationStatus;

  admin: SuperAdmin;

  advisor: Advisor;

  allClients: Client[];

  manageOrgToken?: string;

  orgLogs: OrganizationLog[];
}

export class OrganizationLog {
  operationName: string;

  superadmin: SuperAdmin;

  createdAt: string;
}

export class Session {
  user: User;

  auth: SessionAuth;
}

export class SessionAuth {
  token: string;
}

export class SuperAdmin {
  id: string;

  name: string;

  email: string;

  adminRole: SuperAdminRole;

  allAdvisors?: Nullable<Advisor[]>;

  allOrganizations?: Nullable<ClientOrganization[]>;

  organization?: Nullable<ClientOrganization>;

  settings?: Nullable<Settings>;

  allAdvisorRequests?: Nullable<AdvisorRequest[]>;
}

export class AssignManagerInput {
  name: string;

  orgId: string;

  managerId: string;
}

export class AssignAdvisorToClientInput {
  advisorId: string;

  clientId: string;
}

export class DeleteClientInput {
  orgId: string;

  clients: string[];
}

export class AdvisorRequest {
  id: string;

  client: Client;
}

export class SettingsEmergencySavings {
  interestRate: number;
}

export class SettingsInsurance {
  disability: SettingsInsuranceDisability;

  life: SettingsInsuranceLife;
}

export class SettingsInsuranceDisability {
  incomeCoveragePercent: number;
}

export class SettingsInsuranceLife {
  inflationRate: number;

  incomePercentToReplace: number;

  canadaPensionPlanPayout: number;

  funeralExpenses: number;

  survivorBenefitsUnder65: number;

  survivorBenefitsAbove65: number;

  childrensBenefits: number;
}

export class SettingsDebt {
  refinancingMortgage: SettingsDebtInterest;

  refinancingByAllDebts: SettingsDebtInterest;

  refinancingWithoutMortgage: SettingsDebtInterest;

  mortgageQualification: SettingsDebtMortgageQualification;
}

export class SettingsDebtInterest {
  interestRate: number;

  totalYearsToPayDebt: number;
}

export class SettingsDebtMortgageQualification {
  interestRate: number;

  totalYearsToPayDebt: number;

  downPayment: number;

  propertyInsurance?: Nullable<number>;

  grossDebtServiceRatio?: Nullable<number>;

  totalDebtServiceRatio: number;
}

export class Settings {
  tax: SettingsTax;

  emergencySavings: SettingsEmergencySavings;

  insurance: SettingsInsurance;

  expenses: SettingsExpenses;

  debt: SettingsDebt;

  retirement: SettingsRetirement;
}

export class SettingsRetirement {
  oasStartingAge: number;

  appreciationRate: number;

  inflationRate: number;

  incomeRequiredAtRetirement: number;

  definedContributionMaxLimit: number;

  groupRrspContributionMaxLimit: number;

  rrspContributionMaxLimit: number;

  tfsaContributionMaxLimit: number;

  maxCppPayout: number;

  maxOasPayout: number;
}

export class SettingsExpenses {
  thresholds: SettingsExpensesThresholds;
}

export class SettingsExpensesThresholds {
  transportationTotal: number;

  recreationTotal: number;

  housingTotal: number;

  housingUtilities: number;

  personalCareClothing: number;

  personalCareMedical: number;

  personalCareGroceries: number;
}

export class SettingsTax {
  federal: TaxSlab[];

  ab: TaxSlab[];

  bc: TaxSlab[];

  mb: TaxSlab[];

  nb: TaxSlab[];

  nl: TaxSlab[];

  ns: TaxSlab[];

  nt: TaxSlab[];

  nu: TaxSlab[];

  on: TaxSlab[];

  pe: TaxSlab[];

  qc: TaxSlab[];

  sk: TaxSlab[];

  yt: TaxSlab[];

  cppIncomeThreshold: number;

  cppCredit: TaxCredit;

  eiCredit: TaxCredit;

  personalCredit: number;

  refundableTaxClaim: number;
}

export class TaxSlab {
  limit: number;

  rate: number;
}

export class TaxCredit {
  contributionRate: number;

  threshold: number;
}

export type User = SuperAdmin | Advisor | Client;

type Nullable<T> = T | null;

export class DeleteOrganizationInput {
  id: string;
}

export class DeleteAdvisorInput {
  id: string;
}

export class UpdateAdvisorInput {
  id: string;

  firstName: string;

  lastName: string;

  phone: string;

  title?: Nullable<string>;

  company: string;
}

export class UpdateManagerInput {
  id: string;

  name: string;

  role: SuperAdminRole;
}
